import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import HttpBackend from "i18next-http-backend"

export const fallback = "en"
const namespaces = [
  "about",
  "affiliate",
  "app",
  "capital",
  "cards",
  "careers",
  "credit",
  "defi",
  "earn",
  "basket",
  "faq",
  "fftb",
  "home",
  "license",
  "metadata",
  "navigation",
  "partners",
  "payCustomer",
  "payMerchant",
  "security",
  "token",
  "values",
  "proofOfReserve",
  "custom404",
  "verification",
  "staking",
  "roadmap",
  "rewards",
  "krnoticeboard",
  "prime",
  "roadmap",
  "levelup",
  "cdc",
  "stocks",
  "sports",
  "listing",
  "custody",
]

//Careful, require(`../../locales/${lang}/${namespace}.json`) will require all files under ../../locales
const getEnLanguageResource = () => {
  const resources = {
    en: namespaces.reduce((prev, namespace) => {
      return {
        ...prev,
        [namespace]: require(`../../locales/en/${namespace}.json`),
      }
    }, {}),
  }

  return resources
}

export const languages = [
  "da",
  "en",
  "en-US",
  "es",
  "fr",
  "fr-CA",
  "hu",
  "id",
  "it",
  "pl",
  "pt",
  "pt-BR",
  "ro",
  "ru",
  "tr",
  "vi",
  "zh-TW",
  "ko",
]

export const i18nSSR = i18next.createInstance()
i18nSSR.use(initReactI18next).init({
  fallbackLng: fallback,
  resources: {
    ...getEnLanguageResource(),
  },
  returnObjects: true,
  interpolation: {
    escapeValue: false,
  },
  defaultNS: "metadata",
  partialBundledLanguages: true,
  react: {
    useSuspense: false,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br"],
  },
})

export const i18nClient = i18next.createInstance()
i18nClient
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: fallback,
    resources: {
      ...getEnLanguageResource(),
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: "metadata",
    ns: namespaces,
    partialBundledLanguages: true,
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br"],
    },
  })
/**
 * NOTE: Specific site need to show corresponding languages.
 *       Add the config blow and export it.
 *       import the corresponding config from useStaticSiteConfig.
 */

// WR-665 Brazil site shows pt-BR, other sites show pt
export const brSiteLanguages = [
  "da",
  "en",
  "es",
  "fr",
  "hu",
  "id",
  "it",
  "pl",
  "pt-BR",
  "ro",
  "ru",
  "tr",
  "vi",
  "zh-TW",
]

export const trSiteLanguages = ["en", "tr"]

export const eeaSiteLanguages = ["en"]

// WR-665 Brazil site shows pt-BR, other sites show pt
export const regularSiteLanguages = [
  "da",
  "en",
  "es",
  "fr",
  "hu",
  "id",
  "it",
  "pl",
  "pt",
  "ro",
  "ru",
  "tr",
  "vi",
  "zh-TW",
  "ko",
]

export const usSiteLanguages = [
  "da",
  "en-US",
  "es",
  "fr",
  "hu",
  "id",
  "it",
  "pl",
  "pt",
  "ro",
  "ru",
  "tr",
  "vi",
  "zh-TW",
  "ko",
]

export const frSiteLanguages = ["en", "fr"]
export const caSiteLanguages = ["en", "fr-CA"]
export const itSiteLanguages = ["en", "it"]
export const krSiteLanguages = ["en", "ko"]
