import React from "react"
import { LayoutWrapper } from "./src/components/Layout/LayoutWrapper"
import { I18nextProvider } from "react-i18next"
import { i18nClient } from "./src/i18n/config"

export const wrapRootElement = ({ element, props }) => {
  // console.error = error => {
  //   if (error.includes("Hydration failed")) {
  //     return
  //   } else {
  //     // Log other errors as usual
  //     console.error(error)
  //   }
  // }
  return (
    <I18nextProvider i18n={i18nClient}>
      <LayoutWrapper {...props}>{element}</LayoutWrapper>
    </I18nextProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return <LayoutWrapper {...props}>{element}</LayoutWrapper>
}

export const onInitialClientRender = () => {
  // Load Google Tag Manager script
  const script1 = document.createElement("script")
  script1.src = "https://www.googletagmanager.com/gtag/js?id=G-PC0RNJG7RR"
  script1.async = true
  document.head.appendChild(script1)

  // Load the gtag function
  const script2 = document.createElement("script")
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-PC0RNJG7RR');
  `
  document.head.appendChild(script2)
}
