import React, { useCallback, useEffect, useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import { useCookies } from "react-cookie"
import { regionCookieName } from "@/configs/region"
import { useLocation } from "@reach/router"
import {
  useNavFooter,
  LinkProps,
  RegionsEnum,
} from "@cdc-internal/mkt-nav-footer"
import { useGoMatchedRegion } from "@/modules/NavBar/useGoMatchedRegion"
import { useTranslation } from "react-i18next"
import { useLocaleCookie } from "@/modules/NavBar/useLocaleCookie"
import { usePreservePortuguese } from "@/modules/NavBar/usePreservePortuguese"
import { usePreserveEnglish } from "@/modules/NavBar/usePreserveEnglish"
import { usePreserveTurkish } from "@/modules/NavBar/usePreserveTurkish"
import { extendsExpireYears } from "@/modules/NavBar/utils"
import { usePreserveUSEnglish } from "@/modules/NavBar/usePreserveUSEnglish"
import { usePreserveFrench } from "@/modules/NavBar/usePreserveFrench"
import { usePreserveItalian } from "@/modules/NavBar/usePreserveItalian"
import { navigateToRegion } from "@/modules/NavBar/utils"
import { PageRegionProvider } from "@/modules/NavBar/usePageRegion"
import { getRegionPath } from "@/utils"
import { usePreserveKorean } from "@/modules/NavBar/usePreserveKorean"
import { bitcoinPathRegex, legalHubPathRegex } from "@/utils/routes"

export const AdaptLink = ({
  href,
  style,
  children,
  ...restProps
}: LinkProps) => (
  <GatsbyLink to={href} activeStyle={style} {...restProps}>
    {children}
  </GatsbyLink>
)

const Layout = ({
  children,
  pageContext,
}: {
  children: React.ReactNode
  pageContext: any
}) => {
  const location = useLocation()

  const { i18n } = useTranslation()

  useGoMatchedRegion()

  // NOTE: the order is important,
  // 1. read and set the preferred language
  // 2. set pt or pt-BR on different sites
  // 3. if it's EEA, should ignore language settings, show english
  // 4. if it's TR, should change any language to English except user already choose en or tr
  useLocaleCookie()
  usePreservePortuguese(pageContext.layout)
  usePreserveFrench(pageContext.layout)
  usePreserveEnglish(pageContext.layout)
  usePreserveTurkish(pageContext.layout)
  usePreserveItalian(pageContext.layout)
  usePreserveUSEnglish(pageContext.layout)
  usePreserveKorean(pageContext.layout)

  const [country, setCountry] = useState<RegionsEnum>(
    pageContext?.layout?.toUpperCase()
  )

  useEffect(() => {
    const regionPath = getRegionPath(location.pathname)
    if (regionPath === "") {
      setCountry(RegionsEnum.GLOBAL)
    } else setCountry(regionPath.toUpperCase() as RegionsEnum)
  }, [location.pathname])

  const [cookies, setCookie] = useCookies([regionCookieName, "locale"])

  useEffect(() => {
    if (pageContext.page === "bitcoin") {
      setCountry(cookies["preferred-region"])
    }
  }, [pageContext.page, cookies])

  const countryChangeCallback = useCallback(
    country => {
      setCountry(country)
      setCookie(regionCookieName, country.toUpperCase(), {
        expires: extendsExpireYears(99),
        path: "/",
      })
      navigateToRegion(
        country,
        location.pathname,
        location.search,
        location.hash
      )
    },
    [location, setCookie]
  )

  const langChangeCallback = useCallback(
    lang => {
      i18n.changeLanguage(lang)
      setCookie("locale", lang, {
        path: "/",
      })
    },
    [i18n, setCookie]
  )

  const isBitcoinPage = bitcoinPathRegex.test(location.pathname)
  const isLegalHubPage = legalHubPathRegex.test(location.pathname)

  const navFooterProps = {
    lang: i18n.language,
    country: isBitcoinPage ? country : pageContext?.layout?.toUpperCase(),
    langChangeCallback,
    countryChangeCallback,
    Link: AdaptLink,
    site: 0,
    hasLangCountrySwitch: !isBitcoinPage,
  }

  const { Nav, Footer } = useNavFooter({
    ...navFooterProps,
    ...(isLegalHubPage ? { navbarVariant: "secondary" } : {}),
    ...(isLegalHubPage ? { footerVariant: "secondary" } : {}),
  })

  // NOTE special handle for document/ pages (T&Cs)
  if (
    pageContext.layout === "blank" ||
    location.pathname.endsWith("/offer") ||
    location.pathname.endsWith("/offer/") ||
    location.pathname.endsWith("/resubscription") ||
    location.pathname.endsWith("/resubscription/") ||
    location.pathname.endsWith("/tncacceptance_us") ||
    location.pathname.endsWith("/tncacceptance_us/") ||
    location.pathname.endsWith("/tncacceptance_cardcrostaking") ||
    location.pathname.endsWith("/tncacceptance_cardcrostaking/") ||
    location.pathname.includes("/prime/emailform") ||
    location.pathname.includes("/ben-shapiro") ||
    location.pathname.includes("/ben-shapiro/") ||
    location.pathname.endsWith("/korea-launch") ||
    location.pathname.endsWith("/korea-launch/") ||
    location.pathname.endsWith("/miami_moon_fan2024") ||
    location.pathname.endsWith("/miami_moon_fan2024/") ||
    location.pathname.endsWith("/miami_moon_gp2024") ||
    location.pathname.endsWith("/miami_moon_gp2024/") ||
    location.pathname.includes("/cdc_arena_25y")
  ) {
    return <main>{children}</main>
  }

  if (
    location.pathname.endsWith("/fftb") ||
    location.pathname.endsWith("/fftb/")
  ) {
    return (
      <>
        <Nav />
        <main>{children}</main>
      </>
    )
  }

  if (
    location.pathname.endsWith("/bitcoin") ||
    location.pathname.startsWith("/bitcoin/")
  ) {
    return (
      <main>
        <Nav />
        {children}
        <Footer />
      </main>
    )
  }

  return (
    <PageRegionProvider initPageRegion={country}>
      <main>
        <Nav />
        {children}
        <div id="footer">
          <Footer />
        </div>
      </main>
    </PageRegionProvider>
  )
}

export default Layout
